import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus, FaEye, FaDownload, FaArrowRight, FaComment } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { Input, Button, Form, Space, Table, Divider, notification, Modal, Tag, Row, Col, Menu, Select } from 'antd';
import { CheckOutlined, UserAddOutlined, EditOutlined } from '@ant-design/icons';
import NovoUser from '../../Components/User/NovoUser';
import EditarUser from '../../Components/User/EditarUser';
import { fi } from 'date-fns/locale';
import Item from 'antd/es/list/Item';
import { set } from 'date-fns';

const { Option } = Select;

function ListSummaryType2() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [modalKey, setModalKey] = useState(Date.now());
    const [modalView, setModalView] = useState(false);
    const [modalComment, setModalComment] = useState(false);
    const [selected, setSelected] = useState({});
    const [form] = Form.useForm();
    const [selectedKey, setSelectedKey] = useState('1')
    const [text, setText] = useState('test');
    const [type, setType] = useState();
    const [classification, setClassification] = useState(0);
    const [typeAprove, setTypeAprove] = useState();
    const [comments, setComments] = useState([
        {
            type: 'c1',
            comment: ''
        },
        {
            type: 'c2',
            comment: 0
        },
        {
            type: 'c3',
            comment: ''
        },
        {
            type: 'c4',
            comment: ''
        },
        {
            type: 'c5',
            comment: ''
        },
        {
            type: 'c6',
            comment: ''
        },
        {
            type: 'c7',
            comment: ''
        },
        {
            type: 'c8',
            comment: ''
        },
        {
            type: 'c9',
            comment: ''
        },
        {
            type: 'cAll',
            comment: ''
        },
    ]);

    //Modals para aprovar e reprovar
    const [modalAprove, setModalAprove] = useState(false);
    const [modalReprove, setModalReprove] = useState(false);

    const [searchValue, setSearchValue] = useState({
        nid: '',
        nome: '',
        contacto: '',
    });

    useEffect(() => {
        localStorage.setItem('title', 'Resumos');
        localStorage.setItem('type', '2');
        getResumes();
    }, [])

    const getResumes = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${JSON.parse(sessionStorage.getItem('user')).user}/resume/clinical/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            setDataMarked(response.data.filter(item => item.status?.name == 'Em Revisão'));
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const goView = (record) => {
        try {
            setType(record.clinicCase?.presentation.id)
            setTypeAprove(null)
            setComments(comments.map((item, index) => {
                item.comment = '';
                return item;
            }));
            setSelected(record);
            setModalKey(Date.now());
        } finally {
            setModalView(true);
        }
    }

    const goComment = (type) => {
        try {
            setModalKey(Date.now());
            setText(comments.find(item => item.type == type).comment);
        } finally {
            setModalComment(type);
        }
    }

    const goReprove = async () => {
        console.log(selected);
        try {
            setLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/${JSON.parse(sessionStorage.getItem('user')).user}/resume/clinical/${selected.clinicCase.id}/`, {
                title:comments.find(item => item.type == 'c7').comment,
                category:comments.find(item => item.type == 'c8').comment,
                subcategory:comments.find(item => item.type == 'c9').comment,
                introduction: comments.find(item => item.type == 'c1').comment,
                resume: comments.find(item => item.type == 'c3').comment,
                discussion: comments.find(item => item.type == 'c4').comment,
                conclusion: comments.find(item => item.type == 'c5').comment,
                keyword: comments.find(item => item.type == 'c6').comment,
                presentation: type,
                comment: comments.find(item => item.type == 'cAll').comment,
                status: 7,
                classification: classification
            }, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            notification.success({
                message: 'Sucesso!',
                description: 'Resumo reprovado com sucesso'
            })
            getResumes();
            setModalReprove(false);
            setModalView(false);
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao reprovar resumo'
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    const goAprove = async () => {
        console.log(selected);
        try {
            setLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/${JSON.parse(sessionStorage.getItem('user')).user}/resume/clinical/${selected.clinicCase.id}/`, {
                title:comments.find(item => item.type == 'c7').comment,
                category:comments.find(item => item.type == 'c8').comment,
                subcategory:comments.find(item => item.type == 'c9').comment,
                introduction: comments.find(item => item.type == 'c1').comment,
                resume: comments.find(item => item.type == 'c3').comment,
                discussion: comments.find(item => item.type == 'c4').comment,
                conclusion: comments.find(item => item.type == 'c5').comment,
                keyword: comments.find(item => item.type == 'c6').comment,
                presentation: type,
                status: typeAprove,
                comment: comments.find(item => item.type == 'cAll').comment,
                classification: classification
            }, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            notification.success({
                message: 'Sucesso!',
                description: 'Resumo aprovado com sucesso'
            })
            getResumes();
            setModalAprove(false);
            setModalView(false);
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao aprovar resumo'
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const saveComment = () => {
        setComments(comments.map((item, index) => {
            if (item.type == modalComment) {
                item.comment = text;
            }
            return item;
        }));
        setModalComment(false);
        console.log(comments);
    }

    //Em Validacao
    const columnsMarked = [
        {
            title: "ID",
            dataIndex: "id",
            width: 90,
            key: "id",
            render: (text, record) => (
                <p className='text-sm'>{record.clinicCase?.id}</p>
            )
        },
        {
            title: "Titulo do Resumo",
            dataIndex: "title",
            render: (text, record) => (
                <p className='text-sm'>{record.clinicCase?.title}</p>
            )
        },
        {
            title: "Acções",
            key: "actions",
            width: 90,
            align: "center",
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goView(record)}>
                        <CheckOutlined className='text-white' size={35} />
                    </Button>
                </Space>
            ),
        },
    ];

    const [dataMarked, setDataMarked] = useState([]);

    const filtered = (data) => {
        return data.filter(item => {
            return item.clinicCase?.title.toLowerCase().includes(searchValue.nome.toLowerCase())
        });
    }

    const countWords = (text) => {
        return text?.split(' ').filter(word => word).length;
    }

    const handleMenuClick = e => {
        setSelectedKey(e.key);
    }

    return (
        <div className='flex flex-col gap-4'>
            <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                <div className="flex flex-col items-center sm:flex-row gap-2">
                    <FaSearch />
                    <Input
                        placeholder="Pesquisa por Titulo"
                        value={searchValue.nome}
                        className='sm:w-64'
                        onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
                    />
                </div>
                <Divider className='my-0' />
                {selectedKey == '1' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table
                            size='middle'
                            columns={columnsMarked}
                            dataSource={filtered(dataMarked)}
                            loading={loading}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                }
                <Modal
                    title={"Avaliar Resumo"}
                    visible={modalView}
                    onCancel={() => setModalView(false)}
                    footer={null}
                    width={1000}
                >
                    <div className='w-full'>
                        <div className='w-full bg-white rounded-lg p-4'>
                            <div className='w-full flex flex-col gap-2'>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Titulo</p>
                                    <p className='text-sm rounded-md p-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.title}</p>
                                    <Button className='ml-auto flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => goComment('c7')}>
                                        <FaComment className='mr-2' />Comentar
                                    </Button>
                                </div>
                                <Divider className='my-0' />
                                <div className='w-full flex flex-row gap-4'>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Apresentação</p>
                                        <p className='text-sm rounded-md p-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.presentation.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Categoria</p>
                                        <p className='text-sm rounded-md p-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.category.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Sub-Categoria</p>
                                        <p className='text-sm rounded-md p-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.subcategory.name}</p>
                                    </div>
                                </div>
                                <Divider className='my-0' />
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Introdução</p>
                                    <p className='text-sm rounded-md p-2 mb-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.introduction}</p>
                                    <Button className='ml-auto flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => goComment('c1')}>
                                        <FaComment className='mr-2' />Comentar
                                    </Button>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Resumo do caso</p>
                                    <p className='text-sm rounded-md p-2 mb-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.resume}</p>
                                    <Button className='ml-auto flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => goComment('c3')}>
                                        <FaComment className='mr-2' />Comentar
                                    </Button>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Discussão</p>
                                    <p className='text-sm rounded-md p-2 mb-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.discussion}</p>
                                    <Button className='ml-auto flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => goComment('c4')}>
                                        <FaComment className='mr-2' />Comentar
                                    </Button>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Conclusão</p>
                                    <p className='text-sm rounded-md p-2 mb-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.conclusion}</p>
                                    <Button className='ml-auto flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => goComment('c5')}>
                                        <FaComment className='mr-2' />Comentar
                                    </Button>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold'>Palavras Chave</p>
                                    <p className='text-sm rounded-md p-2' style={{ border: '1px solid gray' }}>{selected.clinicCase?.keyword.map(item => item.name).join(', ')}</p>
                                    <Button className='ml-auto flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => goComment('c6')}>
                                        <FaComment className='mr-2' />Comentar
                                    </Button>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold'>Comentário Geral</p>
                                    <Input.TextArea
                                        value={comments.find(item => item.type == 'cAll').comment}
                                        onChange={(e) => setComments(comments.map((item, index) => {
                                            if (item.type == 'cAll') {
                                                item.comment = e.target.value;
                                            }
                                            return item;
                                        }))}
                                        placeholder='Insira o comentário' />

                                </div>
                                <div className='w-full flex flex-row gap-4 mt-4'>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold'>Tipo de Apresentação</p>
                                        <Select
                                            placeholder="Seleccione o tipo de apresentação"
                                            allowClear
                                            className='w-full'
                                            value={type}
                                            onChange={(e) => setType(e)}
                                        >
                                            <Option value={1}>Oral</Option>
                                            <Option value={2}>Poster</Option>
                                        </Select>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold'>Comentário da Categoria</p>
                                        <Input
                                            value={comments.find(item => item.type == 'c8').comment}
                                            onChange={(e) => setComments(comments.map((item, index) => {
                                                if (item.type == 'c8') {
                                                    item.comment = e.target.value;
                                                }
                                                return item;
                                            }))}
                                            placeholder='Insira o comentário' />
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold'>Comentário da Sub Categoria</p>
                                        <Input
                                            value={comments.find(item => item.type == 'c9').comment}
                                            onChange={(e) => setComments(comments.map((item, index) => {
                                                if (item.type == 'c9') {
                                                    item.comment = e.target.value;
                                                }
                                                return item;
                                            }))}
                                            placeholder='Insira o comentário' />
                                    </div>
                                </div>
                                <div>
                                    <p className='text-sm font-bold'>Pontuação</p>
                                    <Select
                                        placeholder="Seleccione a pontuação do resumo"
                                        allowClear
                                        onChange={(e) => setClassification(e)}
                                    >
                                        <Option value={0}>0</Option>
                                        <Option value={1}>1</Option>
                                        <Option value={2}>2</Option>
                                        <Option value={3}>3</Option>
                                        <Option value={4}>4</Option>
                                        <Option value={5}>5</Option>
                                        <Option value={6}>6</Option>
                                        <Option value={7}>7</Option>
                                        <Option value={8}>8</Option>
                                        <Option value={9}>9</Option>
                                        <Option value={10}>10</Option>
                                        <Option value={11}>11</Option>
                                        <Option value={12}>12</Option>
                                        <Option value={13}>13</Option>
                                        <Option value={14}>14</Option>
                                        <Option value={15}>15</Option>
                                        <Option value={16}>16</Option>
                                        <Option value={17}>17</Option>
                                        <Option value={18}>18</Option>
                                        <Option value={19}>19</Option>
                                        <Option value={20}>20</Option>
                                        <Option value={21}>21</Option>
                                        <Option value={22}>22</Option>
                                        <Option value={23}>23</Option>
                                        <Option value={24}>24</Option>
                                        <Option value={25}>25</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4 justify-between items-center mt-4'>
                                <Button
                                    htmlType="submit"
                                    loading={loading}
                                    className='ml-auto font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                                    onClick={() => setModalReprove(true)}
                                >
                                    Reprovar
                                </Button>
                                <Button
                                    htmlType="submit"
                                    loading={loading}
                                    className='text-white bg-blue-600 font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                                    onClick={() => setModalAprove(true)}
                                >
                                    Aprovar
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Aprovar Resumo"
                    centered
                    visible={modalAprove}
                    onOk={() => goAprove()}
                    onCancel={() => setModalAprove(false)}
                    okButtonProps={{ className: 'bg-blue-600' }}
                    okText="Confirmar"
                >
                    <p className='text-sm font-bold'>Tipo de aprovação</p>
                    <Select
                        placeholder="Seleccione"
                        allowClear
                        className='w-full'
                        value={typeAprove}
                        onChange={(e) => setTypeAprove(e)}
                    >
                        <Option value={5}>Sem Modifições</Option>
                        <Option value={6}>Com Modifições Ligeiras</Option>
                    </Select>

                </Modal>
                <Modal
                    title="Reprovar Resumo"
                    centered
                    visible={modalReprove}
                    onOk={() => goReprove()}
                    onCancel={() => setModalReprove(false)}
                    okButtonProps={{ className: 'bg-blue-600' }}
                    okText="Confirmar"
                >
                    <p>Tem certeza que deseja reprovar este resumo?</p>
                </Modal>
                <Modal
                    title="Comentar"
                    centered
                    visible={modalComment}
                    onOk={() => saveComment()}
                    onCancel={() => setModalComment(false)}
                    okButtonProps={{ className: 'bg-blue-600' }}
                    okText="Guardar"
                    key={modalComment}
                >
                    <Input.TextArea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder='Insira o comentário' />
                </Modal>
            </div>
        </div>
    );
}

export default ListSummaryType2;