import React, { useState, useEffect } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, User } from '@ant-design/icons';
import { Card } from 'antd';
import { QuestionCircleOutlined, MailOutlined, PhoneOutlined, PlusCircleFilled } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';
import { MdPeople, MdCategory, MdSchool, MdPlusOne, MdAdd, MdEditDocument, MdList, MdHistory } from 'react-icons/md';

const Menu = (props) => {

  useEffect(() => {
    localStorage.setItem('title', 'Bem-vindo a Plataforma');
    localStorage.setItem('type', '1');
  },[])


  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3  gap-4 p-2 sm:p-8">
        <Link to="/resumos">
          <Card
            bordered={false}
            hoverable
            cover={<MdList style={{ fontSize: '100px', color: '#00728a' }} />}
          >
            <div className="text-sm sm:text-lg text-center">Resumos</div>
          </Card>
        </Link>
      </div>
    </>
  );
};

export default Menu;