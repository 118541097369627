import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus, FaEye, FaDownload, FaArrowRight } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { Input, Button, Form, Space, Table, Divider, notification, Modal, Tag, Row, Col, Menu, Select } from 'antd';
import { EyeOutlined} from '@ant-design/icons';
import NovoUser from '../../Components/User/NovoUser';
import EditarUser from '../../Components/User/EditarUser';
import { fi } from 'date-fns/locale';

function HistSummary() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [markedPage, setMarkedPage] = useState(true);
  const [modalKey, setModalKey] = useState(Date.now());
  const [modalView, setModalView] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalRev, setModalRev] = useState(false);
  const [modalEditRev, setModalEditRev] = useState(false);
  const [selected, setSelected] = useState({});
  const [form] = Form.useForm();
  const [selectedKey, setSelectedKey] = useState('1')

  //Modals para aprovar e reprovar
  const [modalAprove, setModalAprove] = useState(false);
  const [modalReprove, setModalReprove] = useState(false);

  const [searchValue, setSearchValue] = useState({
    nid: '',
    nome: '',
    contacto: '',
  });

  useEffect(() => {
    localStorage.setItem('title', 'Histórico de Resumos');
    localStorage.setItem('type', '2');
  }, [])

  const goView = (record) => {
    try {
      setSelected(record);
      setModalKey(Date.now());
    } finally {
      setModalView(true);
    }
  }


  //Em Validacao
  const columnsMarked = [
    {
      title: "ID",
      dataIndex: "id",
      width: 90,
      key: "id",
    },
    {
      title: "Titulo do Resumo",
      dataIndex: "title",
    },
    {
      title: "Autor do Resumo",
      dataIndex: "author",
    },
    {
      title: "Data de Submissão",
      dataIndex: "date",
      width: 160,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 130,
      render: (text, record) => (
        <div>
          <Tag color={record.status === "Aprovado" ? "green" : "red"} key={record.status}>
            {record.status}
          </Tag>
        </div>
      ),
    },
    {
      title: "Acções",
      key: "actions",
      width: 90,
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goView(record)}>
            <EyeOutlined className='text-white' size={35} />
          </Button>
        </Space>
      ),
    },
  ];

  const [dataMarked, setDataMarked] = useState([
    {
      id: 1,
      title: "Resumo 1",
      date: "2021-08-20",
      status: "Aprovado",
      apresentacao: "Poster",
      categoria: "Categoria 1",
      subCategoria: "Sub-Categoria 1",
      author: "Feliciano Cruz",
      coAuthors: [
        {
          name: "Nome 1",
          institution: "Instituição 1",
        },
        {
          name: "Nome 2",
          institution: "Instituição 2",
        },
      ],
      introducao: "Introdução do resumo",
      objetivos: "Objetivos do resumo",
      metodos: "Metodos do resumo",
      resultados: "Resultados do resumo",
      conclusao: "Conclusão do resumo",
      palavrasChave: "Palavras Chave do resumo",
    },
    {
      id: 2,
      title: "Resumo 2",
      date: "2021-08-20",
      status: "Reprovado",
      apresentacao: "Oral",
      categoria: "Categoria 2",
      subCategoria: "Sub-Categoria 2",
      author: 'Alberto Fernandez',
      coAuthors: [
        {
          name: "Nome 3",
          institution: "Instituição 3",
        },
        {
          name: "Nome 4",
          institution: "Instituição 4",
        },
      ],
      introducao: "Introdução do resumo",
      objetivos: "Objetivos do resumo",
      metodos: "Metodos do resumo",
      resultados: "Resultados do resumo",
      conclusao: "Conclusão do resumo",
      palavrasChave: "Palavras Chave do resumo",
    },
  ]);

  const filtered = (data) => {
    return data.filter((item) => {
      return (
        item.title.toLowerCase().includes(searchValue.nome.toLowerCase()) ||
        item.author.toLowerCase().includes(searchValue.nome.toLowerCase())
      );
    });
  }

  const countWords = (text) => {
    return text?.split(' ').filter(word => word).length;
  }

  const handleMenuClick = e => {
    setSelectedKey(e.key);
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
        <div className="flex flex-col items-center sm:flex-row gap-2">
          <FaSearch />
          <Input
            placeholder="Pesquisa por Nome"
            value={searchValue.nome}
            className='sm:w-64'
            onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
          />
        </div>
        <Divider className='my-0' />
        {selectedKey == '1' &&
          <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table
              size='middle'
              columns={columnsMarked}
              dataSource={filtered(dataMarked)}
              loading={loading}
              pagination={{ pageSize: 10 }}
            />
          </div>
        }
        <Modal
          title={"Resumo"}
          visible={modalView}
          onCancel={() => setModalView(false)}
          footer={null}
          width={800}
        >
          <div className='w-full'>
            <div className='w-full flex flex-row justify-between items-center mb-0'>
              <p className='text-sm font-bold mb-2'>Pré-Visualização</p>
              <Button className='flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => setModalView(false)}>
                <FaDownload className='mr-2' />Baixar
              </Button>
            </div>
            <div className='w-full bg-white rounded-lg p-4'>
              <div className='w-full flex flex-col gap-4'>
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Titulo</p>
                  <p className='text-sm'>{selected.title}</p>
                </div>
                <Divider className='my-0' />
                <div className='w-full flex flex-row gap-4'>
                  <div className='w-full'>
                    <p className='text-sm font-bold mb-2'>Apresentação</p>
                    <p className='text-sm'>{selected.apresentacao}</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-sm font-bold mb-2'>Categoria</p>
                    <p className='text-sm'>{selected.categoria}</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-sm font-bold mb-2'>Sub-Categoria</p>
                    <p className='text-sm'>{selected.subCategoria}</p>
                  </div>
                </div>
                <Divider className='my-0' />
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Autor</p>
                  <p className='text-sm'>{selected.author}</p>
                </div>
                <Divider className='my-0' />
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Co-Autores</p>
                  <div className='w-full flex flex-col gap-4'>
                    <Table size='small' dataSource={selected.coAuthors} rowKey={(record, index) => index} footer={null} pagination={false}>
                      <Table.Column title="Nome" dataIndex="name" key="name" />
                      <Table.Column title="Instituição" dataIndex="institution" key="institution" />
                    </Table>
                  </div>
                </div>
                <Divider className='my-0' />
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Introdução</p>
                  <p className='text-sm'>{selected.introducao}</p>
                </div>
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Objetivos</p>
                  <p className='text-sm'>{selected.objetivos}</p>
                </div>
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Metodos</p>
                  <p className='text-sm'>{selected.metodos}</p>
                </div>
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Resultados</p>
                  <p className='text-sm'>{selected.resultados}</p>
                </div>
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Conclusão</p>
                  <p className='text-sm'>{selected.conclusao}</p>
                </div>
                <div className='w-full'>
                  <p className='text-sm font-bold mb-2'>Palavras Chave</p>
                  <p className='text-sm'>{selected.palavrasChave}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      </div>
    </div>
  );
}

export default HistSummary;